import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { MdNorth } from "react-icons/md"; // Import icons
import BackgroundVideo from "./components/backgroundvideo"; // Import the BackgroundVideo component

const SAMPLE_PROMPTS = [
  "What is WallerGPT?",
  "What programming languages does Evan know?",
  "What is Evan's current role?",
  "What is Brawl360.com?",
  "What's Evan's most proud accomplishment?",
  "How did Evan build WallerGPT?",
  "What is Evan currently working on?",
];

function App() {
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePromptClick = (prompt) => {
    setInputValue(prompt);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputValue("");
    setResponse("");
    if (!inputValue) return; // Do not submit if input is empty

    setLoading(true);
    try {
      const res = await fetch(
        "https://simple-website-api.onrender.com/query/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ question: inputValue }),
        }
      );
      const data = await res.json();
      setResponse(data.answer);
    } catch (error) {
      console.error("Error:", error);
      setResponse("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <BackgroundVideo />
      <div className="top-button-container">
        <a
          href="https://www.brawl360.com"
          target="_blank"
          rel="noopener noreferrer"
          className="brawl360-button"
        >
          Check out Brawl360.com!
        </a>
      </div>
      <h1 className="title">WallerGPT</h1>
      <div className="response-container">
        <div className="response-box">
          <p className="response-text">{response}</p>
        </div>
      </div>
      <p className="sample-prompt-header-text">Sample Prompts</p>
      <div className="sample-prompt-container">
        {SAMPLE_PROMPTS.map((prompt, index) => (
          <div
            key={index}
            className="sample-prompt"
            onClick={() => handlePromptClick(prompt)}
          >
            <p>{prompt}</p>
          </div>
        ))}
      </div>{" "}
      <div className="form-container">
        <form className="input-container" onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Ask WallerGPT a question..."
          />
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "..." : <MdNorth />}
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;
